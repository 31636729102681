import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"

const link = createHttpLink({
  uri: "https://wp.letri.com/graphql",
  fetch,
  credentials: "include",
})

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
